import { Layout, Container, Link, Intro, Heading } from '~/components'

export default function Custom404() {
  return (
    <Layout>
      <Intro title="404" />
      <br />
      <br />
      <br />
      <Container>
        <Heading>Seite nicht gefunden</Heading>
        <br />
        <p>
          Bitte versuchen Sie es über die Navigation oder über die <Link href="/">Startseite</Link>.
        </p>
      </Container>
    </Layout>
  )
}
